import { IApp } from "@/interfaces";
import { LanguageKey } from "@/store/modules/common/interfaces";
import { DigitalHumanSetting } from "@/store/modules/digital_human/interfaces";
import { clean_string } from "./global";

const isCore42Portal =
  clean_string(process.env.VUE_APP_PORTAL) ===
  clean_string(IApp.Portals.COREHCMS);

const digital_human_setting: Record<string, DigitalHumanSetting> = {
  [IApp.Portals.AI_LAWYER]: {
    welcome_message:
      "Hello I am Adele, a lawyer specializing in cyber laws in the UAE region. I have extensive knowledge and experience in dealing with legal matters related to personal data protection, electronic documents, and the identification of individuals through various means. I am committed to helping my clients navigate the complex legal landscape in the digital age.",
    bot_avatar: "Kayla-incasualsuit-20220818",
    bot_voice_id: "8273e0a033074b5bb98d7ce3ab727bd9",
    intro_popup: {
      title: "AI Lawyer",
      message:
        'Hello I am Adele, a lawyer specializing in cyber laws in the UAE region. Please click <span class="font-weight-bold">Continue</span> to chat with me.'
    },
    members: [],
    language: LanguageKey.ENGLISH,
    logo: require("@/assets/logos/core42-hcms-white.png"),
    reset_chat_history: false,
    width: "",
    height: ""
  },
  [IApp.Portals.AI_BOARD_MEMBER]: {
    welcome_message:
      "Hello, I'm Aiden, a board member at META Corporation. I leverage my extensive experience and expertise to offer valuable insights and guidance, aiding in the company's strategic direction and success.",
    bot_avatar: "Tyler-insuit-20220721",
    bot_voice_id: "e17b99e1b86e47e8b7f4cae0f806aa78",
    intro_popup: {
      title: "AI Board Members",
      message:
        'Hello, I am Aiden, a male board member at META corporation. Please click <span class="font-weight-bold">Continue</span> to chat with me.'
    },
    members: [],
    logo: require("@/assets/logos/core42-hcms-white.png"),
    reset_chat_history: false,
    width: "",
    height: ""
  },
  [IApp.Portals.AL_MUFTI]: {
    welcome_message:
      "Assalamu Alaikum! I'm your AI Mufti for Islamic Fatwas in the UAE. Ask me anything about Islamic law and practices here in the Emirates!",
    bot_avatar: "Tyler-insuit-20220721",
    bot_voice_id: "e17b99e1b86e47e8b7f4cae0f806aa78",
    intro_popup: {
      title: "Al Mufti",
      message:
        "Assalamu Alaikum! I'm your AI Mufti for Islamic Fatwas in the UAE. Ask me anything about Islamic law and practices here in the Emirates!"
    },
    members: [],
    language: LanguageKey.ARABIC,
    logo: require("@/assets/logos/core42-hcms-white.png"),
    reset_chat_history: false,
    width: "",
    height: ""
  },
  [IApp.Portals.FINANCE_MANAGER]: {
    welcome_message:
      "Hello! I'm your AI CEO Assistant. Ask me anything about Financial Benchmarking",
    bot_avatar: "Kayla-incasualsuit-20220818",
    bot_voice_id: "8273e0a033074b5bb98d7ce3ab727bd9",
    intro_popup: {
      title: "AI CEO Assistant",
      message:
        "Hello! I'm your AI CEO Assistant. Ask me anything about Financial Benchmarking"
    },
    members: [],
    language: LanguageKey.ENGLISH,
    logo: isCore42Portal
      ? require("@/assets/logos/core42-hcms-white.png")
      : require("@/assets/logos/inception-hcms-green.png"),
    reset_chat_history: true,
    width: !isCore42Portal ? "200" : "130",
    height: !isCore42Portal ? "200" : "130"
  }
};

export default digital_human_setting;

// {
//   name: "Jacod Marsh",
//   avatar: require("@/assets/images/profile-bot.png"),
//   active: true
// },
// {
//   name: "UAE_local",
//   avatar: require("@/assets/images/female-western.png"),
//   active: false
// },
// {
//   name: "male_local",
//   avatar: require("@/assets/images/male-local.png"),
//   active: false
// }
