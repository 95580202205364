/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ACTIVATE_PUBLISHED_JOB,
  ALL_JOBS_COUNT,
  AUTOCOMPLETE_JOB_SEARCH,
  DELETE_JOB_SOFTLY,
  FETCH_MATCHING_CANDIDATES,
  GET_FAVORITE_CANDIDATES,
  INIT_RECRUITER,
  INTERVIEW_LISTING,
  JOBS_POSTED_BY_RECRUITER,
  JOB_SHORT_URL,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  MATCHING_CANDIDATES,
  MEMBERS_JOBS_STATISTICS,
  POKE_CANDIDATE,
  POKE_CANDIDATE_LOADING,
  POST_JOB,
  POST_JOB_LIMIT_ERROR,
  RECRUITER_LOADING,
  REMOVE_TOP_JOBS_WITH_CANDIDATES,
  RESUME_CANDIDATE_INTERVIEW,
  SAVE_UPDATED_JOB_DESCRIPTION,
  START_CANDIDATE_INTERVIEW,
  TOP_JOBS_WITH_CANDIDATES,
  UPDATE_FAVORITE_CANDIDATE,
  UPDATE_JOB,
  UPDATE_MATCHING_CANDIDATES,
  UPDATE_TOP_MATCHING_CANDIDATES,
  UPDATE_INTERVIEW_STATUS,
  FETCH_INTERVIEW_HISTORY,
  IS_MATCHING_CANDIDATES_EXIST,
  ASSESSMENT_LISTING,
  ASSESSMENT_LISTING_FETCH_LOADING,
  ASSESSMENT_LISTING_DATA,
  JOB_LISTING_PAGINATION,
  JOB_LISTING_DATE_FILTER,
  JOB_LISTING_SEARCHED_JOB_FILTER,
  ZAPPYHIRE_JOB_POST,
  ZAPPYHIRE_CREATE_CANDIDATE,
  ZAPPYHIRE_SSO,
  UPLOAD_KNOWLEDGE_BASE_DOCUMENT,
  GET_KNOWLEDGE_BASE,
  DELETE_KNOWLEDGE_BASE,
  ADVANCE_SEARCH,
  ASSESSMENT_RESULT,
  GET_ASSESSMENT_RESULT,
  REGISTER_NEW_CANDIDATE,
  DELETE_USER,
  USER_MANAGEMENT_LISTING,
  DASHBOARD_USER_CONSUMPTION,
  DASHBOARD_STATS_API,
  RESET_ASSESSMENT_HISTORY
} from "@/store/modules/recruiter/constants";
import {
  activate_published_job,
  fetch_assessment_listing,
  fetch_favorite_candidates,
  fetch_interview_history,
  fetch_interview_listing,
  fetch_members_jobs_stats,
  fetch_total_jobs_count,
  get_jobs_posted_by_recruiter,
  get_job_short_url,
  get_search_candidates,
  is_matching_candidates_exist,
  poke_candidate,
  post_job,
  resume_interview,
  save_updated_job_description,
  soft_delete_job,
  start_interview,
  update_favorite_candidate,
  update_interview_status,
  update_job,
  zappyhire_post_job,
  zappyhire_create_candidate,
  zappyhire_sso,
  upload_knowledge_base,
  get_knowledge_base_document,
  delete_knowledge_base_document,
  advance_search,
  assessment_result,
  get_assessment_result,
  delete_user,
  fetch_user_list,
  user_management_stats_api,
  reset_assessment_history
} from "@/store/apis/recruiter";
import { Commit, Dispatch } from "vuex";
import { Jobs } from "@/interfaces/data_objects/jobs";
import {
  FetchAssessmentListingPayload,
  GetJobsPostedPayload,
  GetJobShortUrlPayload,
  InterviewListing,
  InterviewListingPayload,
  // MatchingCandidatesByIdPayload,
  MembersJobsStatistics,
  PokeCandidatePayload,
  ResumeInterviewPayload,
  SaveUpdatedJobDescriptionPayload,
  SearchCandidatesAccumulator,
  SearchCandidatesPayload,
  SearchedCandidate,
  StartInterviewPayload,
  UpdateFavoriteCandidatePayload,
  UpdateInterviewStatusPayload,
  UpdateJobPayload,
  ZappyhireCreateCandidatePayload,
  ZapphirePostJobResponse,
  ZapphireCreateCandidateResponse,
  KnowledgeBasePayload,
  AssessmentResultApiPayload,
  GetAssessmentResultApiPayload,
  FetchUserListing,
  ResetAssessmentHistoryPayload
} from "@/store/modules/recruiter/interfaces";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { ZappyhirePostjobPayload } from "@/interfaces/recruiter/jobs/post_job/zappyhire_post_job";
import { IAppAssessment } from "@/interfaces";
import { SignupActionPayload } from "../auth/interfaces";
import { signup_user } from "@/store/apis/auth";
export default {
  [POST_JOB]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<unknown | null> => {
    try {
      commit(RECRUITER_LOADING, true);
      const response = await post_job(payload);
      commit(RECRUITER_LOADING, false);
      commit(JOB_LISTING_SEARCHED_JOB_FILTER, null);
      commit(JOB_LISTING_DATE_FILTER, []);
      commit(JOB_LISTING_PAGINATION, 1);
      return response.data.data;
    } catch (e: any) {
      if (e.message.toString().includes("403")) {
        commit(
          POST_JOB_LIMIT_ERROR,
          "Limit Reached. Please contact at info@hcms.ai for more information"
        );
      }
      commit(RECRUITER_LOADING, false);
      return null;
    }
  },
  /**
   * Function to get jobs posted by recruiter
   * Jobs return in pagination
   * After getting jobs loop each job and fetch applicants separately
   * @param payload
   */
  [JOBS_POSTED_BY_RECRUITER]: async (
    { commit }: { commit: Commit },
    payload: GetJobsPostedPayload
  ): Promise<void> => {
    commit(JOBS_POSTED_BY_RECRUITER_LOADING, true);
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    const response = await get_jobs_posted_by_recruiter(payload);
    let jobs = response.data.data;
    jobs = {
      results: jobs.results.map((v: Jobs) => ({
        ...v,
        matching_candidates: [],
        candidates_loading: true
      })),
      total: jobs.total
    };
    if (payload.dashboard) commit(TOP_JOBS_WITH_CANDIDATES, jobs);
    else commit(JOBS_POSTED_BY_RECRUITER, jobs);
    commit(JOBS_POSTED_BY_RECRUITER_LOADING, false);
  },
  /**
   * Vuex action to obtain a short URL for a job and handle the response.
   *
   * This asynchronous action attempts to generate a short URL for a job by making an API call
   * with the specified payload containing job details. If the API call is successful, it resolves
   * with the data received from the response. In case of an error, it commits an error state to the
   * Vuex store's root.
   *
   * @param {Commit} commit - The Vuex `commit` method to update the store's state.
   * @param {GetJobShortUrlPayload} payload - An object containing the job details required to generate the short URL.
   * @returns {Promise<any>} - A promise that resolves to the data part of the response, or undefined if an error occurs.
   *
   * Note: The function assumes that `get_job_short_url` is a service function that handles the API call.
   * The `ROOT_ERROR` is a Vuex mutation used to handle errors globally within the store.
   * It is not specified what the action should commit upon a successful response, which implies that
   * the short URL data may be handled by the component dispatching this action rather than stored in Vuex state.
   */
  [JOB_SHORT_URL]: async (
    { commit }: { commit: Commit },
    payload: GetJobShortUrlPayload
  ): Promise<any> => {
    try {
      const response = await get_job_short_url(payload);
      return response?.data?.data;
    } catch (error) {
      commit(ROOT_ERROR, error, { root: true });
    }
  },
  /**
   * Function to get matching candidates/applicants against job id
   */
  [MATCHING_CANDIDATES]: async (
    { commit }: { commit: Commit },
    payload: SearchCandidatesPayload
  ): Promise<void> => {
    try {
      const response = await get_search_candidates(payload);
      const response_data = response.data.data;
      const candidates_job_based: SearchCandidatesAccumulator =
        response_data.results.reduce(
          (
            accumulator: SearchCandidatesAccumulator,
            obj: SearchedCandidate
          ) => {
            const jobId = obj.job_id;
            if (!jobId) return accumulator;
            if (!accumulator[jobId]) {
              accumulator[jobId] = [];
            }
            accumulator[jobId].push(obj);
            return accumulator;
          },
          {}
        );
      if (payload.dashboard) {
        commit(UPDATE_TOP_MATCHING_CANDIDATES, {
          candidates_job_based
        });
      } else {
        commit(UPDATE_MATCHING_CANDIDATES, {
          candidates_job_based
        });
      }
    } catch (e) {
      if (!payload.job_ids) return;
      const candidates_job_based: SearchCandidatesAccumulator = {};
      for (const job of payload.job_ids) {
        candidates_job_based[job] = [];
      }
      if (payload.dashboard) {
        commit(UPDATE_TOP_MATCHING_CANDIDATES, {
          candidates_job_based
        });
      } else {
        commit(UPDATE_MATCHING_CANDIDATES, {
          candidates_job_based
        });
      }
    }
  },
  /**
   * Function to softly delete job
   * @param job_id:number => jid to remove
   */
  [DELETE_JOB_SOFTLY]: async (
    { commit, getters }: { commit: Commit; getters: any },
    job_id: number
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await soft_delete_job({ job_id });
      // Checking if job exist in top jobs data set
      const top_posted_jobs = getters.TOP_JOBS_WITH_CANDIDATES;
      const exist_job = top_posted_jobs.results.find(
        (v: Jobs) => v.jid === job_id
      );
      // If job exist in top jobs data set => remove & decrement total count
      if (exist_job) {
        commit(REMOVE_TOP_JOBS_WITH_CANDIDATES, job_id);
      }
      // If job not exist in top jobs data set => decrement total count only
      else commit(REMOVE_TOP_JOBS_WITH_CANDIDATES, -100);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  /**
   * Function to get matching candidates/applicants
   */
  [FETCH_MATCHING_CANDIDATES]: async (
    { commit }: { commit: Commit },
    payload: SearchCandidatesPayload
  ): Promise<Candidates.Candidates[] | null> => {
    try {
      const response = await get_search_candidates(payload);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, null, { root: true });
      return null;
    }
  },
  /**
   * Function for autocomplete job search
   */
  [AUTOCOMPLETE_JOB_SEARCH]: async (
    { commit }: { commit: Commit },
    payload: GetJobsPostedPayload
  ): Promise<Jobs[]> => {
    try {
      const response = await get_jobs_posted_by_recruiter(payload);
      return response.data.data.results;
    } catch (e) {
      commit(ROOT_ERROR, null, { root: true });
      return [];
    }
  },
  /**
   * Initialize recruiter data
   */
  [INIT_RECRUITER]: async ({
    dispatch
  }: {
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      const fetch_top_candidates_obj = {
        page: 0,
        limit_per_page: 5,
        dashboard: true
      };
      await dispatch(JOBS_POSTED_BY_RECRUITER, fetch_top_candidates_obj);
      return true;
    } catch (e) {
      return false;
    }
  },
  /**
   * Action to activate published job
   */
  [ACTIVATE_PUBLISHED_JOB]: async (
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await activate_published_job(job_id);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  /**
   * Function to fetch all jobs count
   */
  [ALL_JOBS_COUNT]: async (): Promise<number> => {
    try {
      return (await fetch_total_jobs_count()).data.data;
    } catch (e) {
      return 0;
    }
  },
  /**
   * Function to update job
   */
  [UPDATE_JOB]: async (
    { commit }: { commit: Commit },
    payload: UpdateJobPayload
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await update_job(payload);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  [MEMBERS_JOBS_STATISTICS]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: MembersJobsStatistics
  ): Promise<any> => {
    try {
      const response = await fetch_members_jobs_stats(payload);
      return response.data.data;
    } catch (e) {
      return false;
    }
  },
  /**
   * For getting favorite candidates against job
   */
  [GET_FAVORITE_CANDIDATES]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<any> => {
    try {
      const response = await fetch_favorite_candidates(job_id);
      return response.data.data;
    } catch (e) {
      return [];
    }
  },
  /**
   * For updating favorite candidates against job
   */
  [UPDATE_FAVORITE_CANDIDATE]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: UpdateFavoriteCandidatePayload
  ): Promise<any> => {
    try {
      await update_favorite_candidate(payload);
      return true;
    } catch (e) {
      return false;
    }
  },
  /**
   * Action to poke candidate (send email to candidate)
   * @param commit
   * @param {PokeCandidatePayload} payload
   */
  [POKE_CANDIDATE]: async (
    { commit }: { commit: Commit },
    payload: PokeCandidatePayload
  ): Promise<void> => {
    try {
      commit(POKE_CANDIDATE_LOADING, true);
      const response = await poke_candidate(payload);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      commit(POKE_CANDIDATE_LOADING, false);
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(POKE_CANDIDATE_LOADING, false);
    }
  },
  /**
   * Action to save updated job description during posting new job as custom
   * @param {SaveUpdatedJobDescriptionPayload} payload
   */
  [SAVE_UPDATED_JOB_DESCRIPTION]: async (
    { commit }: { commit: Commit },
    payload: SaveUpdatedJobDescriptionPayload
  ): Promise<void> => {
    try {
      commit(RECRUITER_LOADING, true);
      await save_updated_job_description(payload);
      commit(RECRUITER_LOADING, false);
    } catch (e) {
      commit(RECRUITER_LOADING, false);
    }
  },
  [START_CANDIDATE_INTERVIEW]: async (
    { commit }: { commit: Commit },
    payload: StartInterviewPayload
  ): Promise<any> => {
    try {
      const response = await start_interview(payload);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return [];
    }
  },
  [RESUME_CANDIDATE_INTERVIEW]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: ResumeInterviewPayload
  ): Promise<null | string> => {
    try {
      const response = await resume_interview(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [INTERVIEW_LISTING]: async (
    { commit }: { commit: Commit },
    payload: InterviewListingPayload
  ): Promise<null | InterviewListing> => {
    try {
      const response = await fetch_interview_listing(payload);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [UPDATE_INTERVIEW_STATUS]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: UpdateInterviewStatusPayload
  ): Promise<null | boolean> => {
    try {
      const response = await update_interview_status(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [FETCH_INTERVIEW_HISTORY]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: number
  ): Promise<Interview.InterviewHistoryResponse[]> => {
    try {
      const response = await fetch_interview_history(payload);
      return response.data.data;
    } catch (e) {
      return [];
    }
  },
  [IS_MATCHING_CANDIDATES_EXIST]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<boolean> => {
    try {
      const response = await is_matching_candidates_exist(job_id);
      return response.data.data.exist;
    } catch (e) {
      return false;
    }
  },
  [ASSESSMENT_LISTING]: async (
    { commit }: { commit: Commit },
    payload: FetchAssessmentListingPayload
  ): Promise<void> => {
    try {
      commit(ASSESSMENT_LISTING_FETCH_LOADING, true);
      const response = await fetch_assessment_listing(payload);
      commit(ASSESSMENT_LISTING_DATA, response.data.data);
      commit(ASSESSMENT_LISTING_FETCH_LOADING, false);
    } catch (e) {
      commit(ASSESSMENT_LISTING_FETCH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
    }
  },
  [ZAPPYHIRE_JOB_POST]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: ZappyhirePostjobPayload
  ): Promise<ZapphirePostJobResponse | null> => {
    try {
      const response = await zappyhire_post_job(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [ZAPPYHIRE_CREATE_CANDIDATE]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: ZappyhireCreateCandidatePayload
  ): Promise<ZapphireCreateCandidateResponse | null> => {
    try {
      const response = await zappyhire_create_candidate(
        payload.payload,
        payload.job_id,
        payload.candidate_id
      );
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [ZAPPYHIRE_SSO]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<string | null> => {
    try {
      const response = await zappyhire_sso();
      return response.data.data.token;
    } catch (e) {
      return null;
    }
  },
  [UPLOAD_KNOWLEDGE_BASE_DOCUMENT]: async (
    { commit }: { commit: Commit },
    file_name: string
  ): Promise<string | null> => {
    try {
      const response = await upload_knowledge_base(file_name);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [GET_KNOWLEDGE_BASE]: async (
    { commit }: { commit: Commit },
    payload: KnowledgeBasePayload
  ): Promise<string | null> => {
    try {
      const response = await get_knowledge_base_document(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [DELETE_KNOWLEDGE_BASE]: async (
    { commit }: { commit: Commit },
    uri: string
  ): Promise<string | null> => {
    try {
      const response = await delete_knowledge_base_document(uri);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [ADVANCE_SEARCH]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    text: string
  ): Promise<string | null> => {
    try {
      const response = await advance_search(text);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [ASSESSMENT_RESULT]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: AssessmentResultApiPayload
  ): Promise<IAppAssessment.AssessmentResults | null> => {
    try {
      const response = await assessment_result(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [GET_ASSESSMENT_RESULT]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: GetAssessmentResultApiPayload
  ): Promise<IAppAssessment.AssessmentResults | null> => {
    try {
      const response = await get_assessment_result(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [REGISTER_NEW_CANDIDATE]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: SignupActionPayload
  ): Promise<boolean> => {
    try {
      await signup_user(payload);
      return true;
    } catch (e) {
      return false;
    }
  },
  [USER_MANAGEMENT_LISTING]: async (
    { commit }: { commit: Commit },
    payload: FetchUserListing
  ): Promise<boolean> => {
    try {
      const is_dashboard = payload?.is_dashboard;
      let data = {} as FetchUserListing;
      if (!is_dashboard) {
        data = {
          page: payload.page,
          limit: payload.limit,
          selected_searched_user: payload.selected_searched_user
        } as FetchUserListing;
      }
      const response = await fetch_user_list(payload);

      if (!is_dashboard) {
        data.results = response.data.data.results;
        data.total = response.data.data.total;
        data.results = data.results.map((v: any) => {
          v.selected = false;
          return v;
        });
        commit(USER_MANAGEMENT_LISTING, data);
      }
      commit(DASHBOARD_USER_CONSUMPTION, response.data.data.results);
      return true;
    } catch (e) {
      return false;
    }
  },
  [DASHBOARD_STATS_API]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    date_filter: string[]
  ): Promise<any> => {
    try {
      const response = await user_management_stats_api(date_filter);
      return response.data.data;
    } catch (e) {
      return false;
    }
  },
  [RESET_ASSESSMENT_HISTORY]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: ResetAssessmentHistoryPayload
  ): Promise<any> => {
    try {
      const resp = await reset_assessment_history(payload);
      return resp.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  }
};
