import { Roles } from "@/interfaces/data_objects/roles";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { NotificationsResponse } from "@/interfaces/responses/notifications";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { IAppAssessment } from "@/interfaces";
import { blob } from "aws-sdk/clients/codecommit";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { User } from "@/interfaces/data_objects/user";
/**
 * Common state main interface
 */
export interface CommonState {
  loading: boolean;
  sectors: SectorData[];
  roles: Roles[];
  users_info: SearchUserObject[];
  selected_users: SearchUserObject[];
  searched_job: Jobs | null;
  view_job_state: ViewJobState;
  notifications: Notifications;
  site_direction: SiteDirections;
  app_assessments: IAppAssessment.Assessment[];
  upload_file_chunk_loading: boolean;
}

export interface SectorData {
  sector: string;
}

export interface FetchSkillsGroupActionPayload {
  skills: string[];
}

export enum ViewJobState {
  DEFAULT = "DEFAULT",
  JOB_LISTING = "JOB_LISTING",
  STANDARDISED = "STANDARDISED",
  STANDARDISED_JOB_POST = "STANDARDISED_JOB_POST",
  LINKEDIN_JOB_POST = "LINKEDIN_JOB_POST",
  FILE_JOB_POST = "FILE_JOB_POST",
  TEXT_JOB_POST = "TEXT_JOB_POST",
  EDIT_TEXT_JOB_POST = "EDIT_TEXT_JOB_POST"
}

export interface GetJobMatchActionPayload {
  cv_id: string;
  job_id: number;
}

export interface SkillSearchPayload {
  page: number;
  limit_per_page: number;
  skill_name: string;
}

export interface FetchNotificationListPayload {
  page: number;
  limit: number;
  is_read?: boolean;
}

export interface UpdateNotificationStatusPayload {
  notificationIds: number[];
}

export interface Notifications {
  loading: boolean;
  count: number;
  notifications: NotificationsResponse[];
  action_loading: boolean;
}

export enum SiteDirections {
  RTL = "rtl",
  LTR = "ltr"
}

export enum LanguageKey {
  ENGLISH = "en",
  ARABIC = "ar"
}

export interface AppAssessmentResultPayload {
  assessment_id: number;
  user_id?: number;
}

export interface UploadFileChunkPayload {
  file: File;
  filename: string;
  start: number;
}

export interface SkillList {
  group: string;
  ref_skill: string;
  skill: string;
}

export interface JobData {
  job_id?: number;
  job_title: string;
  job_description: string | undefined;
  job_industry: string | undefined;
  job_type: string;
  job_experience: string | undefined;
  skills_list: string;
  is_standardize: boolean;
  edited?: boolean;
  [key: string]: string | number | boolean | undefined | blob; // Add an index signature
}

export interface UserProfileData {
  user: User;
  profile: Candidates.CandidateDetails;
}

export interface TimeSecondInterface {
  hours: number;
  minutes: number;
  seconds: number;
}
