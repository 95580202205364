import getters from "@/store/modules/digital_human/getters";
import mutations from "@/store/modules/digital_human/mutations";
import actions from "@/store/modules/digital_human/actions";
import { DigitalHumanState } from "@/store/modules/digital_human/interfaces";
import digital_human_setting from "@/utils/digital_human";
import { IApp } from "@/interfaces";

export default {
  namespaced: true,
  state: (): DigitalHumanState => ({
    loading: false,
    bot_background: true,
    bot_setting: digital_human_setting[IApp.Portals.AI_LAWYER],
    hygen_peer_connection: null,
    hygen_render_id: 1,
    hygen_session_id: null,
    hygen_session_info: null,
    hygen_session_error: "",
    session_initialized_successfully: false
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
