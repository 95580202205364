import { hygen_url } from "./base_urls";

export const INIT_HYGEN_SESSION = hygen_url + "v1/streaming.new";

export const START_HYGEN_SESSION = hygen_url + "v1/streaming.start";

export const SPEAK_HYGEN_BOT = hygen_url + "v1/streaming.task";

export const HANDLE_HYGEN_ICE = hygen_url + "v1/streaming.ice";

export const STOP_HYGEN_SESSION = hygen_url + "v1/streaming.stop";

export const INTERRUPT_HYGEN_SESSION = hygen_url + "v1/streaming.interrupt";
