import axios, { AxiosResponse } from "axios";
import {
  APP_ASSESSMENTS,
  FETCH_ASSESSMENT,
  FETCH_ASSESSMENT_PDF,
  FETCH_ASSESSMENT_SUMMARY,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_COUNT,
  FETCH_SKILLS,
  FETCH_SKILLS_GROUP,
  FETCH_USER_WITH_PROFILE,
  FETCH_USERS_INFO,
  GET_JOB_BY_ID,
  GET_JOB_BY_KEY,
  GET_ROLES_LIST,
  GET_UNIQUE_SECTORS,
  NOTIFICATIONS_ACTION,
  UPLOAD_FILE_CHUNK,
  UPLOAD_FILES
} from "@/store/urls/common";
import {
  AppAssessmentResultPayload,
  FetchNotificationListPayload,
  FetchSkillsGroupActionPayload,
  SkillSearchPayload,
  UpdateNotificationStatusPayload
} from "@/store/modules/common/interfaces";

export const get_unique_sectors = async (): Promise<AxiosResponse> => {
  return await axios.get(GET_UNIQUE_SECTORS).then((response) => response.data);
};

export const get_roles_list = async (): Promise<AxiosResponse> => {
  return await axios.get(GET_ROLES_LIST);
};
export const get_job_by_id = async (job_id: number): Promise<AxiosResponse> => {
  return await axios.get(`${GET_JOB_BY_ID}${job_id}`);
};
/**
 * Fetches job details from a remote server using a unique job key.
 *
 * This asynchronous function sends a GET request to a predefined URL
 * concatenated with the job key to retrieve the job's details. The function
 * returns a promise that resolves to the Axios response object containing
 * the job data.
 *
 * @param {string} job_key - The unique identifier for the job to fetch.
 * @returns {Promise<AxiosResponse>} - A promise that resolves to the response from the GET request.
 *
 * Note: The function assumes the existence of a constant `GET_JOB_BY_KEY` that holds the base URL
 * for the job retrieval endpoint. The job key is appended to this base URL to form the full endpoint URL.
 */
export const get_job_by_key = async (
  job_key: string
): Promise<AxiosResponse> => {
  return await axios.get(`${GET_JOB_BY_KEY}${job_key}`);
};

export const fetch_skills_group = async (
  payload: FetchSkillsGroupActionPayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_SKILLS_GROUP, payload);
};

export const fetch_skills = async (
  payload: SkillSearchPayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_SKILLS, payload);
};

export const fetch_user_info = async (name: string): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_USERS_INFO}${name}`);
};

export const fetch_user_with_profile = async (
  uid: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_USER_WITH_PROFILE}${uid}`);
};

export const get_notifications_count = async (): Promise<AxiosResponse> => {
  return await axios.get(FETCH_NOTIFICATIONS_COUNT);
};

export const get_notifications_list = async (
  payload: FetchNotificationListPayload
): Promise<AxiosResponse> => {
  const page = payload.page;
  const limit = payload.limit;
  let query = `?page=${page}&limit=${limit}&`;
  if (payload.is_read) query += `is_read=${payload.is_read}`;
  return await axios.get(`${FETCH_NOTIFICATIONS}${query}`);
};

export const update_notifications_status = async (
  payload: UpdateNotificationStatusPayload
): Promise<AxiosResponse> => {
  return await axios.post(`${NOTIFICATIONS_ACTION}`, payload);
};

export const fetch_assessment_summary = async (
  assessment_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_ASSESSMENT_SUMMARY}${assessment_id}`);
};

export const fetch_assessment_pdf = async (
  assessment_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_ASSESSMENT_PDF}${assessment_id}`);
};

export const fetch_assessment = async (
  user_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_ASSESSMENT}${user_id}`);
};

export const app_assessments = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APP_ASSESSMENTS}`);
};

export const app_assessments_result = async (
  payload: AppAssessmentResultPayload
): Promise<AxiosResponse> => {
  let query = `${payload.assessment_id}/result/`;
  if (payload.user_id) query += `${payload.user_id}`;
  return await axios.get(`${APP_ASSESSMENTS}${query}`);
};

export const app_assessments_result_pdf = async (
  payload: AppAssessmentResultPayload
): Promise<AxiosResponse> => {
  let query = `${payload.assessment_id}/result/`;
  if (payload.user_id) query += `${payload.user_id}/pdf`;
  return await axios.get(`${APP_ASSESSMENTS}${query}`);
};

export const upload_file_chunk = async (
  payload: FormData
): Promise<AxiosResponse> => {
  return await axios.post(`${UPLOAD_FILE_CHUNK}`, payload);
};

export const upload_files = async (
  payload: FormData
): Promise<AxiosResponse> => {
  return await axios.post(UPLOAD_FILES, payload);
};
