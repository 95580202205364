export enum ResumeUploadsStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed"
}

export enum Portals {
  HCMS = "HCMS",
  COREHCS = "COREHCS",
  COREHCMS = "COREHCMS",
  INCEPTION = "INCEPTION",
  DIGITAL_HUMAN = "DIGITAL_HUMAN",
  AI_LAWYER = "AI_LAWYER",
  AI_BOARD_MEMBER = "AI_BOARD_MEMBER",
  AL_MUFTI = "AL_MUFTI",
  FINANCE_MANAGER = "FINANCE_MANAGER"
}

export enum SocketEvents {
  DIGITAL_HUMAN_CONSUMPTION = "digital-human-consumption"
}
