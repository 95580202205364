import {
  ALL_CANDIDATES,
  ASSESSMENT_LISTING_DATA,
  ASSESSMENT_LISTING_FETCH_LOADING,
  ASSESSMENT_LISTING_PAGINATION,
  CANDIDATE_VIEW_PAYLOAD,
  EDITED_STANDARDISED_JOB,
  HOLD_STANDARDISED_JOB,
  INTERVIEW_LISTING,
  INTERVIEW_SEARCHED_JOB,
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  POKE_CANDIDATE_LOADING,
  POST_JOB_LIMIT_ERROR,
  PUSH_TOP_JOB_WITH_CANDIDATES,
  RECOMMEND_JOB_PROFILES,
  RECRUITER_INTERVIEW_SEARCH_JOB_LOADING,
  RECRUITER_LOADING,
  REMOVE_TOP_JOBS_WITH_CANDIDATES,
  RESET_RECRUITER_STATE,
  TOP_JOBS_WITH_CANDIDATES,
  UPDATE_INTERVIEW_PAGINATION,
  UPDATE_MATCHING_CANDIDATES,
  UPDATE_TOP_MATCHING_CANDIDATES,
  VIEW_CANDIDATES_DYNAMICALLY,
  VIEW_CANDIDATES_PAGINATION,
  ASSESSMENT_LISTING_DATES_FILTER,
  DELETE_ASSESSMENT_LISTING_DATES_FILTER,
  SELECTED_CANDIDATE_INTERVIEW_REPORT,
  JOB_LISTING_PAGINATION,
  JOB_LISTING_DATE_FILTER,
  JOB_LISTING_SEARCHED_JOB_FILTER,
  UPDARTE_INTERVIEW_LISTING_OBJECT,
  SEARCH_TEXT,
  USER_MANAGEMENT_LISTING,
  DASHBOARD_USER_CONSUMPTION
} from "@/store/modules/recruiter/constants";
import {
  AllCandidates,
  CandidateViewPayload,
  InterviewListingResult,
  JobsWithCandidates,
  RecruiterState,
  SearchAutocompleteJobPayload,
  SetJobsMutationPayload,
  ViewCandidatesDynamically,
  ViewCandidatesPagination,
  InterviewListingCandidates,
  UpdateMatchingCandiadteMutationPayload,
  SearchedCandidate,
  UpdateZappyInterviewListingObjectPayload,
  UserListingPayload,
  FetchUserListing
} from "@/store/modules/recruiter/interfaces";
import { Jobs, JobsWithSkillsGroup } from "@/interfaces/data_objects/jobs";
import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";
import { AssessmentsListingResponse } from "@/interfaces/responses/assessments/assessments";

export default {
  [RECRUITER_LOADING]: (state: RecruiterState, payload: boolean): void => {
    state.loading = payload;
  },
  [JOBS_POSTED_BY_RECRUITER_LOADING]: (
    state: RecruiterState,
    payload: boolean
  ): void => {
    state.jobs_posted_loading = payload;
  },
  [JOBS_POSTED_BY_RECRUITER]: (
    state: RecruiterState,
    payload: SetJobsMutationPayload
  ): void => {
    state.jobs_posted.total = payload.total;
    state.jobs_posted.results = payload.results;
  },
  [TOP_JOBS_WITH_CANDIDATES]: (
    state: RecruiterState,
    payload: SetJobsMutationPayload
  ): void => {
    state.top_jobs_posted = payload;
  },
  [UPDATE_MATCHING_CANDIDATES]: (
    state: RecruiterState,
    payload: UpdateMatchingCandiadteMutationPayload
  ): void => {
    for (const [key, value] of Object.entries(payload.candidates_job_based)) {
      const job_index = state.jobs_posted.results.findIndex(
        (job: Jobs) => job.jid === parseInt(key)
      );
      if (job_index !== -1) {
        const result = value.filter((candidate: SearchedCandidate) => {
          if (candidate.score) return candidate.score > 0.2;
        });
        state.jobs_posted.results[job_index].candidates_loading = false;
        state.jobs_posted.results[job_index].matching_candidates = result;
      }
    }
  },
  [UPDATE_TOP_MATCHING_CANDIDATES]: (
    state: RecruiterState,
    payload: UpdateMatchingCandiadteMutationPayload
  ): void => {
    for (const [key, value] of Object.entries(payload.candidates_job_based)) {
      const job_index = state.top_jobs_posted.results.findIndex(
        (job: Jobs) => job.jid === parseInt(key)
      );
      if (job_index !== -1) {
        const result = value.filter((candidate: SearchedCandidate) => {
          if (candidate.score) return candidate.score > 0.2;
        });
        state.top_jobs_posted.results[job_index].candidates_loading = false;
        state.top_jobs_posted.results[job_index].matching_candidates = result;
      }
    }
  },
  /**
   * Removing job from top jobs with candidates data set if job_id is +ve
   */
  [REMOVE_TOP_JOBS_WITH_CANDIDATES]: (
    state: RecruiterState,
    job_id: number
  ): void => {
    state.top_jobs_posted.total -= 1;
    if (job_id >= 0) {
      state.top_jobs_posted.results = state.top_jobs_posted.results.filter(
        (v: JobsWithCandidates) => v.jid !== job_id
      );
    }
  },
  [VIEW_CANDIDATES_DYNAMICALLY]: (
    state: RecruiterState,
    payload: ViewCandidatesDynamically
  ): void => {
    state.view_candidates_dynamically = payload;
  },
  [PUSH_TOP_JOB_WITH_CANDIDATES]: (
    state: RecruiterState,
    payload: JobsWithCandidates
  ): void => {
    state.top_jobs_posted.results.pop();
    state.top_jobs_posted.results.unshift(payload);
    state.top_jobs_posted.total += 1;
  },
  // Updating recommend job profiles
  [RECOMMEND_JOB_PROFILES]: (state: RecruiterState, payload: Jobs[]): void => {
    state.recommend_job_profiles = payload;
  },
  [RESET_RECRUITER_STATE]: (state: RecruiterState): void => {
    state.loading = false;
    state.jobs_posted_loading = false;
    state.post_job_limit_error = "";
    state.poke_candidate_loading = false;
    state.add_standardised_job = null;
    state.edited_Standardised_job = false;
    state.view_candidates_dynamically = {
      active: false,
      loading: false,
      job_id: null,
      job_title: "",
      skill: "",
      user_ids: []
    };
    // Using in job listing page
    state.jobs_posted = {
      total: 0,
      results: [],
      pagination: 1,
      date_filter: [],
      searched_job_title: null
    };
    // Using to display jobs in dashboard
    state.top_jobs_posted = { total: 0, results: [] };
    state.recommend_job_profiles = [];
    state.candidate_view_payload = null;
    state.all_candidates = {
      results: [],
      total: 0,
      filtered_count: -1
    };
    state.interviews = {
      search_job_loading: false,
      searched_job: null,
      pagination: 1,
      interview_listing: new Map(),
      selected_candidate: []
    };
    state.view_candidates_pagination = {
      pagination: 1,
      searched_skill_filter: ""
    };
    state.assessment_listing = {
      fetch_loading: false,
      pagination: 1,
      selected_dates: [],
      data: {
        total: 0,
        results: []
      }
    };
    state.knowledge_base = {
      data: [],
      info: {
        flag: false,
        msg: ""
      },
      page: 1,
      limit: 8,
      total: 0
    };
    state.searched_text = "";
  },
  [CANDIDATE_VIEW_PAYLOAD]: (
    state: RecruiterState,
    payload: CandidateViewPayload | null
  ): void => {
    state.candidate_view_payload = payload;
  },
  [ALL_CANDIDATES]: (state: RecruiterState, payload: AllCandidates): void => {
    state.all_candidates = payload;
  },
  /**
   * Set poke candidate loading
   * @param {boolean} payload: true to enable loading, false to cancel loading
   */
  [POKE_CANDIDATE_LOADING]: (state: RecruiterState, payload: boolean): void => {
    state.poke_candidate_loading = payload;
  },
  [POST_JOB_LIMIT_ERROR]: (state: RecruiterState, payload: string): void => {
    state.post_job_limit_error = payload;
  },
  [HOLD_STANDARDISED_JOB]: (
    state: RecruiterState,
    payload: JobsWithSkillsGroup | null
  ): void => {
    state.add_standardised_job = payload;
  },
  /**
   * Set searched job, used to fetch all interviewers of specific job
   * @param state
   * @param payload
   */
  [INTERVIEW_SEARCHED_JOB]: (
    state: RecruiterState,
    payload: SearchJobObject | null
  ): void => {
    state.interviews.searched_job = payload;
  },
  [INTERVIEW_LISTING]: (
    state: RecruiterState,
    payload: Map<number, InterviewListingResult>
  ): void => {
    state.interviews.interview_listing = payload;
  },
  [RECRUITER_INTERVIEW_SEARCH_JOB_LOADING]: (
    state: RecruiterState,
    payload: boolean
  ): void => {
    state.interviews.search_job_loading = payload;
  },
  [VIEW_CANDIDATES_PAGINATION]: (
    state: RecruiterState,
    payload: ViewCandidatesPagination
  ): void => {
    state.view_candidates_pagination = payload;
  },
  [UPDATE_INTERVIEW_PAGINATION]: (
    state: RecruiterState,
    payload: number
  ): void => {
    state.interviews.pagination = payload;
  },
  // For holding that either user edit standardised job or not during job posting
  [EDITED_STANDARDISED_JOB]: (
    state: RecruiterState,
    payload: boolean
  ): void => {
    state.edited_Standardised_job = payload;
  },
  [ASSESSMENT_LISTING_FETCH_LOADING]: (
    state: RecruiterState,
    payload: boolean
  ): void => {
    state.assessment_listing.fetch_loading = payload;
  },
  [ASSESSMENT_LISTING_PAGINATION]: (
    state: RecruiterState,
    payload: number
  ): void => {
    state.assessment_listing.pagination = payload;
  },
  [ASSESSMENT_LISTING_DATA]: (
    state: RecruiterState,
    payload: AssessmentsListingResponse.Root
  ): void => {
    state.assessment_listing.data = payload;
  },
  [ASSESSMENT_LISTING_DATES_FILTER]: (
    state: RecruiterState,
    payload: string[]
  ): void => {
    state.assessment_listing.selected_dates = payload;
  },
  [DELETE_ASSESSMENT_LISTING_DATES_FILTER]: (state: RecruiterState): void => {
    state.assessment_listing.selected_dates = [];
  },
  [SELECTED_CANDIDATE_INTERVIEW_REPORT]: (
    state: RecruiterState,
    candidate: InterviewListingCandidates[]
  ): void => {
    state.interviews.selected_candidate = candidate;
  },
  [JOB_LISTING_PAGINATION]: (state: RecruiterState, page: number): void => {
    state.jobs_posted.pagination = page;
  },
  [JOB_LISTING_DATE_FILTER]: (state: RecruiterState, dates: string[]): void => {
    state.jobs_posted.date_filter = dates;
  },
  [JOB_LISTING_SEARCHED_JOB_FILTER]: (
    state: RecruiterState,
    title: SearchAutocompleteJobPayload | null
  ): void => {
    state.jobs_posted.searched_job_title = title;
  },
  [UPDARTE_INTERVIEW_LISTING_OBJECT]: (
    state: RecruiterState,
    payload: UpdateZappyInterviewListingObjectPayload
  ): void => {
    const obj = state.interviews.interview_listing.get(payload.job_id);
    if (!obj) return;
    obj.zappy_job_id = payload.zappy_job_id;
    state.interviews.interview_listing.set(payload.job_id, obj);
  },
  [SEARCH_TEXT]: (state: RecruiterState, text: string): void => {
    state.searched_text = text;
  },
  [USER_MANAGEMENT_LISTING]: (
    state: RecruiterState,
    payload: FetchUserListing
  ): void => {
    state.user_management_listing = payload;
  },
  [DASHBOARD_USER_CONSUMPTION]: (
    state: RecruiterState,
    payload: UserListingPayload[]
  ): void => {
    state.dashboard_user_management = payload;
  }
};
