import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const authentication_routes: RouteConfig = {
  path: "/auth",
  component: () => import("@/views/auth/AuthenticationIndex.vue"),
  children: [
    {
      path: "",
      redirect: {
        path: "login"
      }
    },
    {
      path: "login",
      name: "login",
      component: () => import("@/views/auth/AuthLogin.vue"),
      meta: {
        user_state: [
          UserState.UNAUTHENTICATED,
          UserState.RESET_PASSWORD_COMPLETED,
          UserState.RESET_PASSWORD
        ]
      }
    },
    // {
    //   path: "signup",
    //   name: "signup",
    //   component: () => import("@/views/auth/AuthSignup.vue"),
    //   meta: {
    //     user_state: [
    //       UserState.UNAUTHENTICATED,
    //       UserState.RESET_PASSWORD_COMPLETED,
    //       UserState.RESET_PASSWORD
    //     ]
    //   }
    // },
    // {
    //   path: "google/:token",
    //   name: "google",
    //   component: () => import("@/views/auth/SocialAuth.vue"),
    //   meta: {
    //     user_state: [UserState.UNAUTHENTICATED]
    //   }
    // },
    // {
    //   path: "linkedin/:token",
    //   name: "linkedin",
    //   component: () => import("@/views/auth/SocialAuth.vue"),
    //   meta: {
    //     user_state: [UserState.UNAUTHENTICATED]
    //   }
    // },
    // {
    //   path: "verify-email",
    //   name: "verify-email",
    //   component: () => import("@/views/auth/VerifyEmail.vue"),
    //   meta: {
    //     user_state: [UserState.EMAIL_VERIFICATION_PENDING]
    //   }
    // },
    // {
    //   path: "email-verified",
    //   name: "email-verified",
    //   component: () => import("@/views/auth/EmailVerified.vue"),
    //   meta: {
    //     user_state: [UserState.EMAIL_VERIFICATION_COMPLETED]
    //   }
    // },
    // {
    //   path: "verify-email/:code",
    //   name: "unauthorized-email-verification",
    //   component: () => import("@/views/auth/EmailVerifyUnauthorized.vue"),
    //   meta: {
    //     user_state: [
    //       UserState.UNAUTHENTICATED,
    //       UserState.EMAIL_VERIFICATION_PENDING
    //     ]
    //   }
    // },
    // {
    //   path: "email-success",
    //   name: "Email Verified",
    //   component: () => import("@/views/auth/EmailVerifiedSuccess.vue"),
    //   meta: {
    //     user_state: [UserState.UNAUTHENTICATED]
    //   }
    // },
    {
      path: "reset-password",
      component: () =>
        import("@/views/auth/reset_password/ResetPasswordIndex.vue"),
      meta: {
        user_state: [UserState.RESET_PASSWORD]
      },
      children: [
        {
          path: "",
          redirect: {
            path: "email-verification"
          }
        },
        {
          path: "email-verification",
          name: "reset-password",
          component: () => import("@/views/auth/reset_password/EmailInput.vue"),
          meta: {
            user_state: [UserState.RESET_PASSWORD]
          }
        },
        {
          path: "verify",
          name: "reset-password-verification",
          component: () =>
            import("@/views/auth/reset_password/TokenVerification.vue"),
          meta: {
            user_state: [UserState.RESET_PASSWORD]
          }
        },
        {
          path: "create-new-password",
          name: "create-new-password",
          component: () =>
            import("@/views/auth/reset_password/CreateNewPassword.vue"),
          meta: {
            user_state: [UserState.RESET_PASSWORD]
          }
        },
        {
          path: "success",
          name: "successfully-reset-password",
          component: () =>
            import("@/views/auth/reset_password/ResetPasswordSuccess.vue"),
          meta: {
            user_state: [UserState.RESET_PASSWORD_COMPLETED]
          }
        },
        {
          path: ":code",
          name: "reset-password-code",
          component: () =>
            import("@/views/auth/reset_password/TokenVerification.vue"),
          meta: {
            user_state: [UserState.RESET_PASSWORD],
            link: true
          }
        }
      ]
    },
    {
      path: "*",
      component: () => import("@/views/auth/AuthNotFound.vue"),
      meta: {
        user_state: [
          UserState.RESET_PASSWORD,
          UserState.UNAUTHENTICATED,
          UserState.EMAIL_VERIFICATION_PENDING,
          UserState.EMAIL_VERIFICATION_COMPLETED
        ]
      }
    }
  ]
};
